import React, { useEffect, useContext } from 'react';
import useSetState from '../../utils/useSetState';
import handleLoginClick from '../../utils/handleLoginClick';
import fetchFeaturedItems from './utils/fetch_data';
import fetchFeaturedArtists from '../../Artists/containers/utils/fetch_data';
import { UserContext } from '../../Context/UserContext/UserContext';
import { FeedbackContext } from '../../Context/FeedbackContext/FeedbackContext';
import Home from '../Home';

const HomeContainer = () => {
  const [state, setState] = useSetState({});

  const { userGlobalState, setUserGlobalState } = useContext(UserContext);
  const { setFeedbackState } = useContext(FeedbackContext);

  useEffect(() => {
    fetchFeaturedArtists({ setState });
    fetchFeaturedItems({ setState });
  }, []);

  return (
    <Home
      data={state}
      onLoginClick={handleLoginClick.bind(null, {
        setUserGlobalState,
        setFeedbackState
      })}
    />
  );
};

export default HomeContainer;
