
import React from "react";
import HomeContainer from '../Home/containers/HomeContainer';

const HomePage = () => {

  return (
    <HomeContainer/>
  )
}

export default HomePage;
