
import React from "react";
import styles from './Grid.module.css'

const Grid = ({ children }) => {
    return (
      <div className={styles.Grid}>
        { children }
      </div>
    )
}

export default Grid;