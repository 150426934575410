import React from 'react';
import { Link, navigate } from 'gatsby';
import styles from './CardVertical.module.css';
import Badge from '@material-ui/core/Badge';
import Tooltip from '@material-ui/core/Badge';

const CardVertical = ({
  title,
  imgUrl,
  description,
  cardLink,
  noDrag,
  position
}) => {
  return (
    <div draggable="false" className={styles.Card}>
      {noDrag ? (
        <div
          onClick={navigateTo.bind(null, cardLink)}
          className={styles.card__thumbWrap}
        >
          <CardImg noDrag={noDrag} imgUrl={imgUrl} styles={styles} />
        </div>
      ) : (
        // To allow pre fetch on the public cards of the website
        <Link to={cardLink}>
          <div className={styles.card__thumbWrap}>
            <CardImg noDrag={noDrag} imgUrl={imgUrl} styles={styles} />
          </div>
        </Link>
      )}
      <div className={styles.card__info + (noDrag ? ' ' + styles.noDrag : '')}>
        <div className={styles.card__info__title}>{title}</div>
        <div className={styles.card__info__description}>{description}</div>
      </div>
      {noDrag ? (
        <div className={styles.badgeBox + ' ' + styles.badgeCount__wrap}>
          <Tooltip title="Drag the card to change the position">
            <Badge
              badgeContent={position + 1 + ''}
              color="secondary"
              style={{
                backgroundColor: 'black',
                color: 'white',
                userSelect: 'none'
              }}
            />
          </Tooltip>
        </div>
      ) : null}
    </div>
  );
};

export default CardVertical;

const navigateTo = (to) => {
  navigate(to);
};

const CardImg = ({ imgUrl, noDrag, styles }) => {
  return (
    <div
      draggable="false"
      style={{ backgroundImage: `url("${imgUrl}")` }}
      className={styles.card__thumb + (noDrag ? ' ' + styles.noDrag : '')}
    />
  );
};
