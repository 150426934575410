
import { api_base_url } from '../../../../global_data';
import axios from "axios";


const fetch_data = async ({ setState }) => {
    
    // const { _id, family } = userGlobalState.user;

    const deckRes = await axios({
        url: `${api_base_url}/deckItems/filter`,
        method: 'POST',
        headers: {
            "Content-type": "application/json; charset=utf-8"
        },
        data: JSON.stringify({
            filterObj: {
                // featured: true,
                public: true,
                isCopy: { $not: { $eq: true } }
            },
            sortObj: {
                sortBy: 'createdAt',
                sortType: 'desc'
            },
            populate: 'artist'
        })
    });

    const data = deckRes.data;

    setState({ deckItems: data });

    
}

export default fetch_data;