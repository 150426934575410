import React from 'react';
import { Link } from 'gatsby';
import Navbar from '../lib/components/Navbar/Navbar';
import styles from './Home.module.css';
import Grid from '../lib/components/Grid/Grid';
import CenterContainer from '../lib/components/CenterContainer/CenterContainer';
import GridSkeletons from '../lib/components/GridSkeletons/GridSkeletons';
import CardVertical from '../lib/components/CardVertical/CardVertical';
// import HomeHeader from '../lib/components/HomeHeader/HomeHeader';
import { Helmet } from 'react-helmet';
import Button from '@material-ui/core/Button';

const Home = ({ data, onLoginClick }) => {
  const { deckItems, artists } = data;

  return (
    <div>
      <Helmet>
        <title>Tagtool Cloud Home</title>
        <meta property="og:title" content="Tagtool Cloud" />
        <meta property="og:description" content="Tagtool Cloud" />
        <meta name="twitter:title" content="Tagtool Cloud" />
        <meta name="twitter:description" content="Tagtool Cloud" />
        <meta name="twitter:card" content="Tagtool Cloud" />
      </Helmet>
      <Navbar />
      <CenterContainer>
        <div className={styles.firstSection}>
          <div className={styles.firstSection__left}>
            <h1>Share Your Art</h1>
            <p className={styles.firstSection__subtitle}>
              Join a community of digital artists and share your art with the
              world. Store your deck items safely on the cloud.
            </p>
            <Button onClick={onLoginClick} variant="contained" color="primary" size="large">
              Join
            </Button>
          </div>
          <div
            className={styles.firstSection__right}
            // style={{ height: '600px' }}
          />
        </div>

        {/* Decks */}

        <div className={styles.section}>
          <div id="public-artworks" className="separator" />
          <h2>Most Recent Items</h2>
          <Grid>
            {deckItems ? (
              deckItems.map((item) => {
                const description = (
                  <>
                    by{' '}
                    <Link to={'/artist?_id=' + item.artist._id}>
                      <span className={styles.card__info__author}>
                        {item.artist.name}
                      </span>
                    </Link>
                  </>
                );

                return (
                  <CardVertical
                    key={item._id}
                    cardLink={`/deckItem/?_id=${item._id}&by=${item.artist.name}`}
                    title={item.title}
                    description={description}
                    imgUrl={item.imgUrl}
                  />
                );
              })
            ) : (
              <GridSkeletons />
            )}
          </Grid>
        </div>

        <div className={styles.section}>
          <div id="artists" className="separator" />
          <h2>Artists</h2>
          <Grid>
            {artists ? (
              artists.map((artist) => {
                return (
                  <CardVertical
                    key={artist._id}
                    title={artist.name}
                    imgUrl={artist.avatarImgUrl}
                    cardLink={'/artist?_id=' + artist._id}
                    description={
                      (artist.deckItems || []).filter(
                        (ele) => !ele.isCopy /*&& ele.featured*/ && ele.public
                      ).length + ' Public Artworks'
                    }
                  />
                );
              })
            ) : (
              <GridSkeletons />
            )}
          </Grid>
        </div>
      </CenterContainer>
      <br />
      <br />
      <br />
    </div>
  );
};

export default Home;
