import { api_base_url } from '../../../../global_data';
import axios from 'axios';

const fetch_data = async ({ setState }) => {
  const res = await axios({
    url: `${api_base_url}/artists/filter`,
    method: 'POST',
    headers: {
      'Content-type': 'application/json; charset=utf-8'
    },
    data: JSON.stringify({
      filterObj: {
        // featured: true
        'deckItems.0': { $exists: true }
      },
      sortObj: {
        sortBy: 'createdAt',
        sortType: 'desc'
      },
      populate: 'deckItems'
    })
  });

  const data = res.data;

  setState({ artists: data });
};

export default fetch_data;
