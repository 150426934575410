
import React from "react";
import Skeleton from '@material-ui/lab/Skeleton';

const GridSkeletons = () => {
    return (
      <>
        <Skeleton animation='wave' variant="rect" width={'100%'} height={277} />
        <Skeleton animation='wave' variant="rect" width={'100%'} height={277} />
        <Skeleton animation='wave' variant="rect" width={'100%'} height={277} />
        <Skeleton animation='wave' variant="rect" width={'100%'} height={277} />
        <Skeleton animation='wave' variant="rect" width={'100%'} height={277} />
        <Skeleton animation='wave' variant="rect" width={'100%'} height={277} />
        <Skeleton animation='wave' variant="rect" width={'100%'} height={277} />
        <Skeleton animation='wave' variant="rect" width={'100%'} height={277} />
      </>
    )
  }

  export default GridSkeletons;
